import { lazy } from 'react';
import RoleGuard from 'src/components/RoleGuard';
import { Role } from 'src/models/roles';
import routeNames from 'src/utils/routeNames';

import { Loader } from './Loader';

const Games = Loader(lazy(() => import('src/pages/Games')));

const managementRoutes = [
    {
        path: '',
        children: [
            {
                path: routeNames.management.games,
                element: (
                    <RoleGuard availableRoles={[Role.MODERATOR, Role.ADMIN]}>
                        <Games />
                    </RoleGuard>
                ),
            },
        ],
    },
];

export default managementRoutes;
