import { lazy, Suspense } from 'react';
import Guest from 'src/components/Guest';
import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
    (
        <Suspense fallback={<SuspenseLoader />}>
            <Component {...props} />
        </Suspense>
    );

// Auth
const Login = Loader(lazy(() => import('src/pages/Auth/Login')));
const Register = Loader(lazy(() => import('src/pages/Auth/Register')));
const RecoverPassword = Loader(lazy(() => import('src/pages/Auth/RecoverPassword')));

const authRoutes = [
    {
        path: 'login',
        element: (
            <Guest>
                <Login />
            </Guest>
        ),
    },
    {
        path: 'register',
        element: (
            <Guest>
                <Register />
            </Guest>
        ),
    },
    {
        path: 'recover-password',
        element: (
            <Guest>
                <RecoverPassword />
            </Guest>
        ),
    },
];

export default authRoutes;
