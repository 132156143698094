const DELETE_USER = 'DELETE_USER';
const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
const DELETE_USER_FAIL = 'DELETE_USER_FAIL';
const GET_USERS = 'GET_USERS';
const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
const GET_USERS_FAIL = 'GET_USERS_FAIL';
const GET_ROLES = 'GET_ROLES';
const GET_ROLES_FAIL = 'GET_ROLES_FAIL';
const GET_ROLES_SUCCESS = 'GET_ROLES_SUCCESS';
const POST_ACTIVE = 'POST_ACTIVE';
const POST_ACTIVE_FAIL = 'POST_ACTIVE_FAIL';
const POST_ACTIVE_SUCCESS = 'POST_ACTIVE_SUCCESS';
export {
    DELETE_USER,
    DELETE_USER_FAIL,
    DELETE_USER_SUCCESS,
    GET_ROLES,
    GET_ROLES_FAIL,
    GET_ROLES_SUCCESS,
    GET_USERS,
    GET_USERS_FAIL,
    GET_USERS_SUCCESS,
    POST_ACTIVE,
    POST_ACTIVE_FAIL,
    POST_ACTIVE_SUCCESS,
};
