import {
    APPROVE_CAMPAIGN,
    APPROVE_CAMPAIGN_FAIL,
    APPROVE_CAMPAIGN_SUCCESS,
    CREATE_CAMPAIGN,
    CREATE_CAMPAIGN_FAIL,
    CREATE_CAMPAIGN_SUCCESS,
    GET_AUDIENCE,
    GET_AUDIENCE_FAIL,
    GET_AUDIENCE_SUCCESS,
    GET_CAMPAIGNS,
    GET_CAMPAIGNS_FAIL,
    GET_CAMPAIGNS_REQUESTS,
    GET_CAMPAIGNS_REQUESTS_FAIL,
    GET_CAMPAIGNS_REQUESTS_SUCCESS,
    GET_CAMPAIGNS_SUCCESS,
    GET_GENDERS,
    GET_GENDERS_FAIL,
    GET_GENDERS_SUCCESS,
} from '../constants/ads.constants';

const initialState = {
    campaigns: {
        loading: false,
        error: null,
        result: {
            campaigns: [],
            totalCount: 0,
        },
    },
    campaignsRequests: {
        loading: false,
        error: null,
        result: {
            campaigns: [],
            totalCount: 0,
        },
    },
    audiences: {
        loading: false,
        error: null,
        result: {
            audiences: [],
        },
    },
    genders: {
        loading: false,
        error: null,
        result: {
            genders: [],
        },
    },
    createdCampaign: {
        loading: false,
        error: null,
        result: null,
    },
};
const AdsReducer = (state = initialState, action) => {
    const { type, payload, error, meta } = action;
    switch (type) {
        case CREATE_CAMPAIGN:
            return { ...state, createdCampaign: { loading: true, error: null, result: null }, ...payload };
        case CREATE_CAMPAIGN_SUCCESS:
            return { ...state, createdCampaign: { loading: false, error: null, result: payload.data } };
        case CREATE_CAMPAIGN_FAIL:
            return { ...state, createdCampaign: { loading: false, error, result: null } };
        default:
            return state;
        case GET_CAMPAIGNS:
            return {
                ...state,
                campaigns: { loading: true, error: null, result: initialState.campaigns.result ?? [] },
            };
        case GET_CAMPAIGNS_SUCCESS:
            return {
                ...state,
                campaigns: {
                    loading: false,
                    error: null,
                    result: {
                        campaigns: [...state.campaigns.result.campaigns, ...payload.data.campaigns],
                        totalCount: payload.data.totalCount,
                    },
                },
            };
        case GET_CAMPAIGNS_FAIL:
            return {
                ...state,
                campaigns: {
                    loading: false,
                    error,
                    result: {
                        campaigns: [],
                        totalCount: 0,
                    },
                },
            };
        case GET_CAMPAIGNS_REQUESTS:
            return {
                ...state,
                campaignsRequests: { loading: true, error: null, result: initialState.campaignsRequests.result ?? [] },
            };
        case GET_CAMPAIGNS_REQUESTS_SUCCESS:
            return {
                ...state,
                campaignsRequests: {
                    loading: false,
                    error: null,
                    result: {
                        campaigns: [...state.campaignsRequests.result.campaigns, ...payload.data.campaigns],
                        totalCount: payload.data.totalCount,
                    },
                },
            };
        case GET_CAMPAIGNS_REQUESTS_FAIL:
            return {
                ...state,
                campaignsRequests: {
                    loading: false,
                    error,
                    result: state.campaignsRequests.result,
                },
            };
        case APPROVE_CAMPAIGN:
            return {
                ...state,
                campaignsRequests: { loading: true, error: null, result: state.campaignsRequests.result },
            };
        case APPROVE_CAMPAIGN_SUCCESS:
            return {
                ...state,
                campaignsRequests: {
                    loading: false,
                    error: null,
                    result: {
                        campaigns: [
                            ...state.campaignsRequests.result.campaigns.map((campaign) =>
                                campaign.id === meta.previousAction.data.id ? { ...campaign, approved: true } : campaign
                            ),
                        ],
                        totalCount: payload.data.totalCount,
                    },
                },
            };
        case APPROVE_CAMPAIGN_FAIL:
            return {
                ...state,
                campaignsRequests: {
                    loading: false,
                    error,
                    result: state.campaignsRequests.result,
                },
            };
        case GET_AUDIENCE:
            return {
                ...state,
                ads: { loading: true, error: null, result: initialState.audiences.result },
            };
        case GET_AUDIENCE_SUCCESS:
            return {
                ...state,
                ads: {
                    loading: false,
                    error: null,
                    result: {
                        audiences: payload.data.audiences,
                    },
                },
            };
        case GET_AUDIENCE_FAIL:
            return {
                ...state,
                ads: {
                    loading: false,
                    error,
                    result: {
                        audiences: [],
                    },
                },
            };
        case GET_GENDERS:
            return {
                ...state,
                genders: { loading: true, error: null, result: initialState.genders.result },
            };
        case GET_GENDERS_SUCCESS:
            return {
                ...state,
                genders: {
                    loading: false,
                    error: null,
                    result: {
                        genders: payload.data.genders,
                    },
                },
            };
        case GET_GENDERS_FAIL:
            return {
                ...state,
                genders: {
                    loading: false,
                    error,
                    result: {
                        genders: [],
                    },
                },
            };
    }
};

export default AdsReducer;
