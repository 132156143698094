import config from './config';

// file to hold api routes as constants
const signupApi = `/auth/register`;
const loginApi = `/auth/login`;
const restoreLoginApi = '/auth/restore';
const refreshTokenApi = '/token/refresh';
const usersApi = `/users`;
const logoutApi = '';
const settingsApi = '/settings/background';

const createCampaignApi = '/ads/campaigns';

const createPaymentMethodApi = '/payments';

const deleteUserApi = '/users/id/:id';
const getUsersApi = '/users/page/:page';
const manageUserActiveStatusApi = '/users/:userId/active';
const getRolesApi = '/roles';

const stickerApi = '/stickers';
const deleteStickerApi = '/stickers/id/:id';
const getStickersApi = '/stickers/page/:page';
const getTagsApi = '/tags';

const musicApi = `/music`;
const deleteMusicApi = '/music/id/:id';
const getMusicApi = '/music/page/:page';
const getGenres = '/genres';

const createEmailTemplateApi = '/email/template';
const editEmailTemplateApi = '/email/template/:objectId';
const getEmailTemplateByNameApi = '/email/template/:templateName';
const getEmailTemplatesApi = '/email/templates';
const deleteEmailTemplate = '/email/template/:name';

const deleteFeedbackApi = '/feedback/id/:id';
const getFeedbackApi = '/feedback/page/:page';
const resolveFeedbackApi = '/feedback/:id/resolved';

const getCampaignsApi = '/ads/campaigns/page/:page';
const getAudiencesApi = '/ads/audiences';
const getGendersApi = '/genders';

const getProfilePaymentApi = '/users/profile/payments';
const getPaymentMethodsApi = '/users/:userId/payments';
const deletePaymentApi = '/payments/:id';
const setPreferredPaymentApi = '/payments/:id/preferred';

const deletePostApi = '/feed/id/:id';
const getPostsApi = '/feed/page/:page';

const deleteUsernameCheckApi = '/users/usernames/id/:id';
const getUsernameCheckApi = '/users/usernames/page/:page';
const editUsernameApi = '/users/usernames/:id/edit';
const addUsernameApi = '/users/usernames';

const subscribeToOzPlusApi = '/users/plus/subscribe';

export const buildApiRoute = (route: string, args: any) => {
    const { isIamApi } = args;
    const keys = Object.keys(args);
    let newRoute = route;
    keys.forEach((key) => {
        newRoute = newRoute.replace(`:${key}`, args[key]);
    });

    return isIamApi ? `${config.iamApiUrl}${newRoute}` : newRoute;
};
export {
    addUsernameApi,
    createCampaignApi,
    createEmailTemplateApi,
    createPaymentMethodApi,
    deleteEmailTemplate,
    deleteFeedbackApi,
    deleteMusicApi,
    deletePaymentApi,
    deletePostApi,
    deleteStickerApi,
    deleteUserApi,
    deleteUsernameCheckApi,
    editEmailTemplateApi,
    editUsernameApi,
    getAudiencesApi,
    getCampaignsApi,
    getEmailTemplateByNameApi,
    getEmailTemplatesApi,
    getFeedbackApi,
    getGendersApi,
    getGenres,
    getMusicApi,
    getPaymentMethodsApi,
    getPostsApi,
    getProfilePaymentApi,
    getRolesApi,
    getStickersApi,
    getTagsApi,
    getUsernameCheckApi,
    getUsersApi,
    loginApi,
    logoutApi,
    manageUserActiveStatusApi,
    musicApi,
    refreshTokenApi,
    resolveFeedbackApi,
    restoreLoginApi,
    setPreferredPaymentApi,
    settingsApi,
    signupApi,
    stickerApi,
    subscribeToOzPlusApi,
    usersApi,
};
