const DELETE_MUSIC = 'DELETE_MUSIC';
const DELETE_MUSIC_FAIL = 'DELETE_MUSIC_FAIL';
const DELETE_MUSIC_SUCCESS = 'DELETE_MUSIC_SUCCESS';
const GET_MUSIC = 'GET_MUSIC';
const GET_MUSIC_SUCCESS = 'GET_MUSIC_SUCCESS';
const GET_MUSIC_FAIL = 'GET_MUSIC_FAIL';
const GET_GENRES = 'GET_GENRES';
const GET_GENRES_FAIL = 'GET_GENRES_FAIL';
const GET_GENRES_SUCCESS = 'GET_GENRES_SUCCESS';

export {
    DELETE_MUSIC,
    DELETE_MUSIC_FAIL,
    DELETE_MUSIC_SUCCESS,
    GET_GENRES,
    GET_GENRES_FAIL,
    GET_GENRES_SUCCESS,
    GET_MUSIC,
    GET_MUSIC_FAIL,
    GET_MUSIC_SUCCESS,
};
