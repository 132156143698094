const DELETE_STICKER = 'DELETE_STICKER';
const DELETE_STICKER_SUCCESS = 'DELETE_STICKER_SUCCESS';
const DELETE_STICKER_FAIL = 'DELETE_STICKER_FAIL';
const GET_STICKERS = 'GET_STICKERS';
const GET_STICKERS_SUCCESS = 'GET_STICKERS_SUCCESS';
const GET_STICKERS_FAIL = 'GET_STICKERS_FAIL';
const GET_TAGS = 'GET_TAGS';
const GET_TAGS_FAIL = 'GET_TAGS_FAIL';
const GET_TAGS_SUCCESS = 'GET_TAGS_SUCCESS';

export {
    DELETE_STICKER,
    DELETE_STICKER_FAIL,
    DELETE_STICKER_SUCCESS,
    GET_STICKERS,
    GET_STICKERS_FAIL,
    GET_STICKERS_SUCCESS,
    GET_TAGS,
    GET_TAGS_FAIL,
    GET_TAGS_SUCCESS,
};
