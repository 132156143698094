import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import AdsReducer from './ads.reducer';
import AuthenticationReducer from './authentication.reducer';
import EmailTemplatesReducer from './emailTemplates.reducer';
import FeedbacksReducer from './feedback.reducer';
import MusicReducer from './music.reducer';
import PaymentsReducer from './payment.reducer';
import PostsReducer from './post.reducer';
import StickersReducer from './sticker.reducer';
import UsersReducer from './user.reducer';
import usernamesCheckReducer from './usernames.reducer';

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['authentication'],
};

const reducers = combineReducers({
    authentication: AuthenticationReducer,
    payments: PaymentsReducer,
    stickers: StickersReducer,
    music: MusicReducer,
    users: UsersReducer,
    posts: PostsReducer,
    feedbacks: FeedbacksReducer,
    ads: AdsReducer,
    usernames: usernamesCheckReducer,
    emailTemplates: EmailTemplatesReducer,
});

const appReducer = persistReducer(persistConfig, reducers);

export default appReducer;
