const LOGOUT = 'LOGOUT';
const SAVE_AUTHENTICATION = 'SAVE_AUTHENTICATION';
const CLEAR_AUTHENTICATION = 'CLEAR_AUTHENTICATION';
const LOGIN = 'LOGIN';
const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
const LOGIN_FAIL = 'LOGIN_FAIL';
const RESTORE = 'RESTORE';
const RESTORE_FAIL = 'RESTORE_FAIL';
const RESTORE_SUCCESS = 'RESTORE_SUCCESS';

const SUBSCRIBE_TO_OZ_PLUS = 'SUBSCRIBE_TO_OZ_PLUS';
const SUBSCRIBE_TO_OZ_PLUS_SUCCESS = 'SUBSCRIBE_TO_OZ_PLUS_SUCCESS';
const SUBSCRIBE_TO_OZ_PLUS_FAIL = 'SUBSCRIBE_TO_OZ_PLUS_FAIL';

export {
    CLEAR_AUTHENTICATION,
    LOGIN,
    LOGIN_FAIL,
    LOGIN_SUCCESS,
    LOGOUT,
    RESTORE,
    RESTORE_FAIL,
    RESTORE_SUCCESS,
    SAVE_AUTHENTICATION,
    SUBSCRIBE_TO_OZ_PLUS,
    SUBSCRIBE_TO_OZ_PLUS_FAIL,
    SUBSCRIBE_TO_OZ_PLUS_SUCCESS,
};
