import { useAuth0 } from '@auth0/auth0-react';
import { FC, ReactNode, useCallback, useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { setSession } from 'src/services/authentication';
import routeNames from 'src/utils/routeNames';

interface AuthenticatedProps {
    children: ReactNode;
}

const Authenticated: FC<AuthenticatedProps> = (props: { children: any }) => {
    const { children } = props;
    const { isAuthenticated, isLoading, getIdTokenClaims, getAccessTokenSilently } = useAuth0();

    const setSessionIfAuthenticated = useCallback(async () => {
        if (isAuthenticated) {
            const idToken = await getIdTokenClaims();
            const accessToken = await getAccessTokenSilently();
            setSession(accessToken, idToken.__raw, null);
        }
    }, [isAuthenticated, getIdTokenClaims, getAccessTokenSilently]);

    useEffect(() => {
        setSessionIfAuthenticated();
    }, [setSessionIfAuthenticated]);

    const location = useLocation();
    const [requestedLocation, setRequestedLocation] = useState<string | null>(null);

    if (!isLoading) {
        if (!isAuthenticated) {
            if (location.pathname !== requestedLocation) {
                setRequestedLocation(location.pathname);
            }
            return <Navigate to={`${routeNames.auth.login}?next=${encodeURI(requestedLocation)}`} />;
        }

        if (requestedLocation && location.pathname !== requestedLocation) {
            setRequestedLocation(null);
            return <Navigate to={requestedLocation} />;
        }

        return <>{children}</>;
    }
    return <></>;
};

export default Authenticated;
