const GET_EMAIL_TEMPLATES = 'GET_EMAIL_TEMPLATES';
const GET_EMAIL_TEMPLATES_SUCCESS = 'GET_EMAIL_TEMPLATES_SUCCESS';
const GET_EMAIL_TEMPLATES_FAIL = 'GET_EMAIL_TEMPLATES_FAIL';
const DELETE_EMAIL_TEMPLATE = 'DELETE_EMAIL_TEMPLATE';
const DELETE_EMAIL_TEMPLATE_SUCCESS = 'DELETE_EMAIL_TEMPLATE_SUCCESS';
const DELETE_EMAIL_TEMPLATE_FAIL = 'DELETE_EMAIL_TEMPLATE_FAIL';

export {
    DELETE_EMAIL_TEMPLATE,
    DELETE_EMAIL_TEMPLATE_FAIL,
    DELETE_EMAIL_TEMPLATE_SUCCESS,
    GET_EMAIL_TEMPLATES,
    GET_EMAIL_TEMPLATES_FAIL,
    GET_EMAIL_TEMPLATES_SUCCESS,
};
