import { clearAuthentication, saveAuthentication } from 'src/store/actions/authenticationActions';
import { logoutApi, refreshTokenApi } from 'src/utils/api';

const requestSuccessInterceptor = (store, req) => {
    const accessToken = window.localStorage.getItem('idToken');

    req.headers.Authorization = `Bearer ${accessToken}`;
    // req.headers.Authorization = `Bearer ${store.getState().authentication.access_token}`;

    if (req.url === refreshTokenApi) {
        req.headers.Authorization = `Bearer ${accessToken}`;
        // req.headers.Authorization = `Bearer ${store.getState().authentication.access_token}`;
        req.data = { refresh_token: store.getState().authentication.refresh_token };
    }

    return req;
};

const responseSuccessInterceptor = (store, res) => {
    if (res.config.url === logoutApi) {
        // clear authentication reducer
        store.dispatch(clearAuthentication());
    }

    if (res.config.url === refreshTokenApi) {
        // if refresh token was fired and response 200, save new token in the reducer
        store.dispatch(saveAuthentication(res.data));
    }

    return Promise.resolve(res);
};

const responseErrorInterceptor = (store, res) => {
    if (res?.config?.url === refreshTokenApi) {
        // if refresh token was fired and error, clear authentication reducer (most likely refresh token expired)
        store.dispatch(clearAuthentication());
    }

    // can show notification here if you want

    return Promise.reject(res);
};

export { requestSuccessInterceptor, responseErrorInterceptor, responseSuccessInterceptor };
