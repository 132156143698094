import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { refreshTokenApi } from 'src/utils/api';
import config from 'src/utils/config';

export const refreshTokenLogic = (client) => {
    const iamClient = axios.create({
        baseURL: config.iamApiUrl,
        timeout: parseInt(config.apiTimeout),
        withCredentials: true,
    });
    const body = {
        refresh_token: '',
    };
    return iamClient.post(refreshTokenApi, body, { withCredentials: true });
};

export const createRefreshToken = (client) => {
    return createAuthRefreshInterceptor(client, () => refreshTokenLogic(client), {
        pauseInstanceWhileRefreshing: true,
        statusCodes: [401],
    });
};
