// file to hold react routes as constants
const usersBase = '/users';
const adsBase = '/ads';
const authBase = '/auth';

const routeNames = {
    index: '/',
    auth: {
        login: authBase + '/login',
        register: authBase + '/register',
        recoverPassword: authBase + '/recover-password',
    },
    users: {
        home: usersBase,
        profile: usersBase + '/profile',
        generated: usersBase + '/generated',
    },
    reports: {
        home: '/reports',
    },
    management: {
        games: '/games',
        stickers: '/stickers',
        soundEffects: '/soundEffects',
        settings: '/settings',
        usernames: '/users/usernames',
        music: '/music',
        emails: { index: '/emails', create: '/emails/create', edit: '/email/template' },
        coins: '/coins',
        history: '/history',
    },
    ads: {
        campaigns: {
            home: adsBase + '/campaigns',
            requests: adsBase + '/campaigns/requests',
        },
    },
    feedback: {
        index: '/feedback',
    },
    posts: {
        index: '/posts',
    },
    cloudTagging: {
        index: '/cloud/tagging',
        tags: '/cloud/tags',
    },
    error: {
        s500: '/status/500',
        maintenance: 'status/maintenance',
    },
};

export default routeNames;
