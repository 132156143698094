import { Box, styled } from '@mui/material';
import { Link } from 'react-router-dom';
import routeNames from 'src/utils/routeNames';

const LogoWrapper = styled(Link)(
    ({ theme }) => `
        color: ${theme.palette.text.primary};
        padding: ${theme.spacing(0, 1, 0, 0)};
        display: flex;
        text-decoration: none;
        font-weight: ${theme.typography.fontWeightBold};
`
);

const LogoSignWrapper = styled(Box)(
    () => `
        width: 100px;
        margin-top: 4px;
        transform: scale(.8);
`
);

const Logo = () => {
    return (
        <LogoWrapper to={routeNames.index}>
            <LogoSignWrapper>
                <img src="/icon-192x192.png" width="100%" alt="logo" />
            </LogoSignWrapper>
        </LogoWrapper>
    );
};

export default Logo;
