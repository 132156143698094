import { useAuth0 } from '@auth0/auth0-react';
// import useAuth from './useAuth';
import { useMemo } from 'react';
import { Role } from 'src/models/roles';

const useRoles = (): Role[] | undefined => {
    const { user } = useAuth0();

    const roles = useMemo(() => {
        if (!user) {
            return undefined;
        }

        return user[`user/roles`] as Role[];
    }, [user]);
    return roles;
};

export default useRoles;
