import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';
import config from 'src/utils/config';

export const createApolloClient = () => {
    const accessToken = window.localStorage.getItem('idToken');

    return new ApolloClient({
        link: new HttpLink({
            uri: config.graphqlEndpoint,
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        }),
        cache: new InMemoryCache(),
    });
};
