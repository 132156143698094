const ADD_USERNAME = 'ADD_USERNAMES';
const ADD_USERNAME_SUCCESS = 'ADD_USERNAMES_SUCCESS';
const ADD_USERNAME_FAIL = 'ADD_USERNAMES_FAIL';
const GET_USERNAMES = 'GET_USERNAMES';
const GET_USERNAMES_SUCCESS = 'GET_USERNAMES_SUCCESS';
const GET_USERNAMES_FAIL = 'GET_USERNAMES_FAIL';
const DELETE_USERNAME = 'DELETE_USERNAME';
const DELETE_USERNAME_SUCCESS = 'DELETE_USERNAME_SUCCESS';
const DELETE_USERNAME_FAIL = 'DELETE_USERNAME_FAIL';
const EDIT_USERNAME = 'EDIT_USERNAME';
const EDIT_USERNAME_SUCCESS = 'EDIT_USERNAME_SUCCESS';
const EDIT_USERNAME_FAIL = 'EDIT_USERNAME_FAIL';

export {
    ADD_USERNAME,
    ADD_USERNAME_FAIL,
    ADD_USERNAME_SUCCESS,
    DELETE_USERNAME,
    DELETE_USERNAME_FAIL,
    DELETE_USERNAME_SUCCESS,
    EDIT_USERNAME,
    EDIT_USERNAME_FAIL,
    EDIT_USERNAME_SUCCESS,
    GET_USERNAMES,
    GET_USERNAMES_FAIL,
    GET_USERNAMES_SUCCESS,
};
