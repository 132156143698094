import {
    DELETE_STICKER,
    DELETE_STICKER_FAIL,
    DELETE_STICKER_SUCCESS,
    GET_STICKERS,
    GET_STICKERS_FAIL,
    GET_STICKERS_SUCCESS,
    GET_TAGS,
    GET_TAGS_FAIL,
    GET_TAGS_SUCCESS,
} from '../constants/stickers.constants';

const initialState = {
    deletedSticker: {
        loading: false,
        error: null,
        result: null,
    },
    stickers: {
        loading: true,
        error: null,
        result: {
            stickers: [],
            totalCount: 0,
        },
    },
    tags: {
        loading: true,
        error: null,
        tagsData: [],
    },
};

const stickerReducer = (state = initialState, action) => {
    const { type, payload, error, meta } = action;
    switch (type) {
        case DELETE_STICKER:
            return { ...state, deletedSticker: { loading: true, error: null, result: null }, ...payload };
        case DELETE_STICKER_SUCCESS:
            return {
                ...state,
                deletedSticker: { loading: false, error: null, result: payload.data },
                stickers: {
                    loading: false,
                    error: null,
                    result: {
                        stickers: state.stickers.result.stickers.filter(
                            (sticker) => sticker.id !== meta.previousAction.data.id
                        ),
                        totalCount: state.stickers.result.totalCount - 1,
                    },
                },
            };
        case DELETE_STICKER_FAIL:
            return { ...state, deletedSticker: { loading: false, error, result: [] } };

        default:
            return state;

        case GET_STICKERS:
            return {
                ...state,
                stickers: { loading: true, error: null, result: initialState.stickers.result ?? [] },
            };
        case GET_STICKERS_SUCCESS:
            return {
                ...state,
                stickers: {
                    loading: false,
                    error: null,
                    result: {
                        stickers: [...state.stickers.result.stickers, ...payload.data.stickers],
                        totalCount: payload.data.totalCount,
                    },
                },
            };
        case GET_STICKERS_FAIL:
            return {
                ...state,
                stickers: {
                    loading: false,
                    error,
                    result: {
                        stickers: [],
                        totalCount: 0,
                    },
                },
            };

        case GET_TAGS:
            return {
                ...state,
                tags: { loading: true, error: null, tagsData: initialState.tags.tagsData ?? [] },
            };
        case GET_TAGS_SUCCESS:
            return {
                ...state,
                tags: {
                    loading: false,
                    error: null,
                    tagsData: [...state.tags.tagsData, ...payload.data.tags],
                },
            };
        case GET_TAGS_FAIL:
            return {
                ...state,
                tags: { loading: false, error, tagsData: [] },
            };
    }
};

export default stickerReducer;
