import {
    DELETE_EMAIL_TEMPLATE,
    DELETE_EMAIL_TEMPLATE_FAIL,
    DELETE_EMAIL_TEMPLATE_SUCCESS,
    GET_EMAIL_TEMPLATES,
    GET_EMAIL_TEMPLATES_FAIL,
    GET_EMAIL_TEMPLATES_SUCCESS,
} from '../constants/emailTemplates.constants';
import { FetchState } from '../reducer.types';

export type EmailTemplateState = {
    emailTemplates: FetchState;
};

const initialState: EmailTemplateState = {
    emailTemplates: {
        loading: false,
        error: null,
        result: {
            data: [],
            totalCount: 0,
        },
    },
};

const emailTemplatesReducer = (state = initialState, action): EmailTemplateState => {
    const { type, error, payload, meta } = action;
    switch (type) {
        case GET_EMAIL_TEMPLATES:
            return { ...state, emailTemplates: { ...state.emailTemplates, loading: true, error: null } };
        case GET_EMAIL_TEMPLATES_SUCCESS:
            return {
                ...state,
                emailTemplates: {
                    loading: false,
                    error: null,
                    result: {
                        data: [...state.emailTemplates.result.data, ...payload.data.templates.TemplatesMetadata],
                        totalCount: payload.data.totalCount,
                    },
                },
            };
        case GET_EMAIL_TEMPLATES_FAIL:
            return {
                ...state,
                emailTemplates: {
                    ...state.emailTemplates,
                    loading: false,
                    error,
                },
            };
        case DELETE_EMAIL_TEMPLATE:
            return { ...state, emailTemplates: { ...state.emailTemplates, loading: true, error: null } };
        case DELETE_EMAIL_TEMPLATE_SUCCESS:
            return {
                ...state,
                emailTemplates: {
                    loading: false,
                    error: null,
                    result: {
                        data: state.emailTemplates.result.data.filter(
                            (emailTemplate) => emailTemplate.Name !== meta.previousAction.data.name
                        ),
                        totalCount: state.emailTemplates.result.totalCount - 1,
                    },
                },
            };
        case DELETE_EMAIL_TEMPLATE_FAIL:
            return { ...state, emailTemplates: { ...state.emailTemplates, loading: false, error } };

        default:
            return state;
    }
};

export default emailTemplatesReducer;
