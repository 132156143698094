import { signupApi } from 'src/utils/api';
import iamHttpClient from 'src/utils/iamHttpClient';

const logout = () => {
    setSession(null, null, null);
};

export const setSession = (accessToken: string | null, idToken: string | null, refreshToken: string | null): void => {
    if (accessToken) {
        localStorage.setItem('accessToken', accessToken);
        iamHttpClient().defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    } else {
        localStorage.removeItem('accessToken');
        delete iamHttpClient().defaults.headers.common.Authorization;
    }
    if (idToken) {
        localStorage.setItem('idToken', idToken);
    } else {
        localStorage.removeItem('idToken');
    }
    if (refreshToken) {
        localStorage.setItem('refreshToken', refreshToken);
    } else {
        localStorage.removeItem('refreshToken');
    }
};

const signUp = async (firstName, lastName, username, email, password) => {
    try {
        const body = {
            firstName,
            lastName,
            username,
            email,
            password,
        };

        const { data } = await iamHttpClient().post(signupApi, body);
        return data;
    } catch (e) {
        throw e.response;
    }
};

export { logout, signUp };
