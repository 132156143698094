import axios from 'axios';
import MiddlewareAxios from 'redux-axios-middleware';
import config from 'src/utils/config';

import { requestSuccessInterceptor, responseErrorInterceptor, responseSuccessInterceptor } from './interceptors';
import { createRefreshToken } from './refreshToken';

export const appClient = axios.create({
    baseURL: config.adminApiUrl,
    timeout: parseInt(config.apiTimeout),
});

createRefreshToken(appClient);

const middlewareConfig = {
    interceptors: {
        request: [
            {
                success(store, req) {
                    return requestSuccessInterceptor(store, req);
                },
            },
        ],
        response: [
            {
                success(store, res) {
                    return responseSuccessInterceptor(store, res);
                },
                error(store, res) {
                    return responseErrorInterceptor(store, res);
                },
            },
        ],
    },
};

export default MiddlewareAxios(appClient, middlewareConfig);
