export type UserRole = 'super_admin' | 'admin';

export interface User {
    id: string;
    objectId: string;
    firstName: string;
    lastName: string;
    bio: string;
    username: string;
    email: string;
    phone: string;
    photo: string;
    roles: UserRole[];
    [key: string]: any;
}

export enum Role {
    SUPER_ADMIN = 'super_admin',
    ADMIN = 'admin',
    MODERATOR = 'moderator',
}

export interface Roles {
    id: string;
    objectId: string;
    name: string;
    [key: string]: any;
}
