const CREATE_CAMPAIGN = 'CREATE_CAMPAIGN';
const CREATE_CAMPAIGN_SUCCESS = 'CREATE_CAMPAIGN_SUCCESS';
const CREATE_CAMPAIGN_FAIL = 'CREATE_CAMPAIGN_FAIL';
const APPROVE_CAMPAIGN = 'APPROVE_CAMPAIGN';
const APPROVE_CAMPAIGN_SUCCESS = 'APPROVE_CAMPAIGN_SUCCESS';
const APPROVE_CAMPAIGN_FAIL = 'APPROVE_CAMPAIGN_FAIL';
const GET_AUDIENCE = 'GET_AUDIENCE';
const GET_AUDIENCE_SUCCESS = 'GET_AUDIENCE_SUCCESS';
const GET_AUDIENCE_FAIL = 'GET_AUDIENCE_FAIL';
const GET_CAMPAIGNS = 'GET_CAMPAIGNS';
const GET_CAMPAIGNS_SUCCESS = 'GET_CAMPAIGNS_SUCCESS';
const GET_CAMPAIGNS_FAIL = 'GET_CAMPAIGNS_FAIL';
const GET_CAMPAIGNS_REQUESTS = 'GET_CAMPAIGNS_REQUESTS';
const GET_CAMPAIGNS_REQUESTS_SUCCESS = 'GET_CAMPAIGNS_REQUESTS_SUCCESS';
const GET_CAMPAIGNS_REQUESTS_FAIL = 'GET_CAMPAIGNS_REQUESTS_FAIL';
const GET_GENDERS = 'GET_GENDERS';
const GET_GENDERS_SUCCESS = 'GET_GENDERS_SUCCESS';
const GET_GENDERS_FAIL = 'GET_GENDERS_FAIL';

export {
    APPROVE_CAMPAIGN,
    APPROVE_CAMPAIGN_FAIL,
    APPROVE_CAMPAIGN_SUCCESS,
    CREATE_CAMPAIGN,
    CREATE_CAMPAIGN_FAIL,
    CREATE_CAMPAIGN_SUCCESS,
    GET_AUDIENCE,
    GET_AUDIENCE_FAIL,
    GET_AUDIENCE_SUCCESS,
    GET_CAMPAIGNS,
    GET_CAMPAIGNS_FAIL,
    GET_CAMPAIGNS_REQUESTS,
    GET_CAMPAIGNS_REQUESTS_FAIL,
    GET_CAMPAIGNS_REQUESTS_SUCCESS,
    GET_CAMPAIGNS_SUCCESS,
    GET_GENDERS,
    GET_GENDERS_FAIL,
    GET_GENDERS_SUCCESS,
};
