import {
    DELETE_POST,
    DELETE_POST_FAIL,
    DELETE_POST_SUCCESS,
    GET_POSTS,
    GET_POSTS_FAIL,
    GET_POSTS_SUCCESS,
} from '../constants/posts.constants';

const initialState = {
    deletedPost: {
        loading: false,
        error: null,
        result: null,
    },
    posts: {
        loading: true,
        error: null,
        result: {
            posts: [],
            totalCount: 0,
        },
    },
};

const postReducer = (state = initialState, action) => {
    const { type, payload, error, meta } = action;
    switch (type) {
        case DELETE_POST:
            return { ...state, deletedPost: { loading: true, error: null, result: null }, ...payload };
        case DELETE_POST_SUCCESS:
            return {
                ...state,
                deletedPost: { loading: false, error: null, result: payload.data },
                posts: {
                    loading: false,
                    error: null,
                    result: {
                        posts: state.posts.result.posts.filter((post) => post.id !== meta.previousAction.data.id),
                        totalCount: state.posts.result.totalCount - 1,
                    },
                },
            };
        case DELETE_POST_FAIL:
            return { ...state, deletedPost: { loading: false, error, result: null } };

        default:
            return state;

        case GET_POSTS:
            return {
                ...state,
                posts: { loading: true, error: null, result: initialState.posts.result },
            };
        case GET_POSTS_SUCCESS:
            return {
                ...state,
                posts: {
                    loading: false,
                    error: null,
                    result: {
                        posts: [...state.posts.result.posts, ...payload.data.posts],
                        totalCount: payload.data.totalCount,
                    },
                },
            };
        case GET_POSTS_FAIL:
            return {
                ...state,
                posts: {
                    loading: false,
                    error,
                    result: {
                        posts: [],
                        totalCount: 0,
                    },
                },
            };
    }
};

export default postReducer;
