import { applyMiddleware, compose, createStore } from 'redux';
import logger from 'redux-logger';
import { persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import httpMiddleware from 'src/middlewares/http';
import config from 'src/utils/config';

import appReducer from './reducers';

const middlewares = [thunk, httpMiddleware];
let composeFn = compose;

if (config.isDevEnv || config.isLocalEnv) {
    middlewares.push(logger);
    composeFn = (window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] as typeof compose) || compose;
}

const store = composeFn(applyMiddleware(...middlewares))(createStore)(appReducer);
const persistor = persistStore(store);

export { persistor, store };
