import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import routeNames from 'src/utils/routeNames';

import { Loader } from './Loader';

// Status
const Status404 = Loader(lazy(() => import('src/pages/Status/Status404')));
const Status500 = Loader(lazy(() => import('src/pages/Status/Status500')));
const StatusMaintenance = Loader(lazy(() => import('src/pages/Status/Maintenance')));

const baseRoutes = [
    {
        path: '',
        element: <Navigate to={routeNames.ads.campaigns.home} />,
    },
    {
        path: 'status',
        children: [
            {
                path: routeNames.error.s500,
                element: <Status500 />,
            },
            {
                path: routeNames.error.maintenance,
                element: <StatusMaintenance />,
            },
        ],
    },
    {
        path: '*',
        element: <Status404 />,
    },
];

export default baseRoutes;
