import { lazy } from 'react';
import RoleGuard from 'src/components/RoleGuard';
import { Role } from 'src/models/roles';
import routeNames from 'src/utils/routeNames';

import { Loader } from './Loader';

const GeneratedUsers = Loader(lazy(() => import('src/pages/FakeUsers')));

const managementRoutes = [
    {
        path: '',
        children: [
            {
                path: routeNames.users.generated,
                element: (
                    <RoleGuard availableRoles={[Role.MODERATOR, Role.ADMIN]}>
                        <GeneratedUsers />
                    </RoleGuard>
                ),
            },
        ],
    },
];

export default managementRoutes;
