import { Box } from '@mui/material';
import { node } from 'prop-types';
import { FC, ReactNode } from 'react';
import { Outlet } from 'react-router-dom';

interface BaseLayoutProps {
    children?: ReactNode;
}

const BaseLayout: FC<BaseLayoutProps> = ({ children }) => {
    return (
        <Box
            sx={{
                flex: 1,
                height: '100%',
            }}
        >
            {children || <Outlet />}
        </Box>
    );
};

BaseLayout.propTypes = {
    children: node,
};

export default BaseLayout;
