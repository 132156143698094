import { lazy } from 'react';
import RoleGuard from 'src/components/RoleGuard';
import { Role } from 'src/models/roles';
import routeNames from 'src/utils/routeNames';

import { Loader } from './Loader';

const EmailsIndex = Loader(lazy(() => import('src/pages/Emails')));
const EmailsCreate = Loader(lazy(() => import('src/pages/Emails/create')));
const EmailsEdit = Loader(lazy(() => import('src/pages/Emails/edit')));

const emailsRoutes = [
    {
        path: '',
        children: [
            {
                path: routeNames.management.emails.index,
                element: (
                    <RoleGuard availableRoles={[Role.MODERATOR, Role.ADMIN]}>
                        <EmailsIndex />
                    </RoleGuard>
                ),
            },
            {
                path: routeNames.management.emails.create,
                element: (
                    <RoleGuard availableRoles={[Role.MODERATOR, Role.ADMIN]}>
                        <EmailsCreate />
                    </RoleGuard>
                ),
            },
            {
                path: routeNames.management.emails.edit,
                children: [
                    {
                        path: ':templateName',
                        element: <EmailsEdit />,
                    },
                ],
            },
        ],
    },
];

export default emailsRoutes;
