const local = 'LOCAL';
const dev = 'DEV';

const config = {
    env: process.env.REACT_APP_ENV,
    environments: {
        local,
        dev,
        uat: 'UAT',
        prod: 'PROD',
    },
    isLocalEnv: process.env.REACT_APP_ENV === local,
    isDevEnv: process.env.REACT_APP_ENV === dev,
    adminApiUrl: process.env.REACT_APP_API_URL,
    adminApiUrlTest: process.env.REACT_APP_API_URL_TEST,
    iamApiUrl: process.env.REACT_APP_IAM_API_URL,
    backboneUrl: process.env.REACT_APP_BACKBONE_API_URL,
    backboneUrlTest: process.env.REACT_APP_BACKBONE_API_URL_TEST,
    apiTimeout: process.env.REACT_APP_API_TIMEOUT,
    stripeApiKey: process.env.REACT_APP_STRIPE_API_KEY,
    cookieDomain: process.env.REACT_APP_COOKIE_DOMAIN,
    graphqlEndpoint: process.env.REACT_APP_GRAPHQL,
    client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
};

export default config;
