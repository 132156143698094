import { Box, List, ListItem, ListItemText, Menu, MenuItem, styled } from '@mui/material';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import routeNames from 'src/utils/routeNames';

const ListWrapper = styled(Box)(
    ({ theme }) => `
        .MuiTouchRipple-root {
            display: none;
        }

        .MuiListItem-root {
            transition: ${theme.transitions.create(['color', 'fill'])};

            &.MuiListItem-indicators {
                padding: ${theme.spacing(1, 2)};

                .MuiListItemText-root {
                    .MuiTypography-root {
                        &:before {
                            height: 4px;
                            width: 22px;
                            opacity: 0;
                            visibility: hidden;
                            display: block;
                            position: absolute;
                            bottom: -10px;
                            transition: all .2s;
                            border-radius: ${theme.general.borderRadiusLg};
                            content: "";
                            background: ${theme.colors.primary.main};
                        }
                    }
                }

                &.active,
                &:active,
                &:hover {

                    background: transparent;

                    .MuiListItemText-root {
                        .MuiTypography-root {
                            &:before {
                                opacity: 1;
                                visibility: visible;
                                bottom: 0px;
                            }
                        }
                    }
                }
            }
        }
`
);

const HeaderMenu = () => {
    const { t }: { t: any } = useTranslation();

    const ref = useRef<any>(null);
    const [isOpen, setOpen] = useState<boolean>(false);

    const handleClose = (): void => {
        setOpen(false);
    };

    return (
        <>
            <ListWrapper>
                <List disablePadding component={Box} display="flex">
                    <ListItem
                        classes={{ root: 'MuiListItem-indicators' }}
                        button
                        component={NavLink}
                        to={routeNames.ads.campaigns.home}
                    >
                        <ListItemText primaryTypographyProps={{ noWrap: true }} primary={t('Dashboard')} />
                    </ListItem>
                </List>
            </ListWrapper>
            <Menu disableScrollLock anchorEl={ref.current} onClose={handleClose} open={isOpen}>
                <MenuItem component={NavLink} to="/docs/contact-support">
                    {t('Contact support')}
                </MenuItem>
            </Menu>
        </>
    );
};

export default HeaderMenu;
