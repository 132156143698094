const DELETE_FEEDBACK = 'DELETE_FEEDBACK';
const DELETE_FEEDBACK_SUCCESS = 'DELETE_FEEDBACK_SUCCESS';
const DELETE_FEEDBACK_FAIL = 'DELETE_FEEDBACK_FAIL';
const GET_FEEDBACKS = 'GET_FEEDBACKS';
const GET_FEEDBACKS_SUCCESS = 'GET_FEEDBACKS_SUCCESS';
const GET_FEEDBACKS_FAIL = 'GET_FEEDBACKS_FAIL';
const POST_RESOLVED = 'POST_RESOLVED';
const POST_RESOLVED_FAIL = 'POST_RESOLVED_FAIL';
const POST_RESOLVED_SUCCESS = 'POST_RESOLVED_SUCCESS';

export {
    DELETE_FEEDBACK,
    DELETE_FEEDBACK_FAIL,
    DELETE_FEEDBACK_SUCCESS,
    GET_FEEDBACKS,
    GET_FEEDBACKS_FAIL,
    GET_FEEDBACKS_SUCCESS,
    POST_RESOLVED,
    POST_RESOLVED_FAIL,
    POST_RESOLVED_SUCCESS,
};
