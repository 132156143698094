import { FC, ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import useRoles from 'src/hooks/useRoles';
import { Role } from 'src/models/roles';
import routeNames from 'src/utils/routeNames';

interface RoleProps {
    children: ReactNode;
    availableRoles: Role[];
}

const RoleGuard: FC<RoleProps> = ({ children, availableRoles }) => {
    const userRoles = useRoles();

    if (userRoles?.some((role) => availableRoles.includes(role)) || userRoles?.includes(Role.SUPER_ADMIN)) {
        return <>{children}</>;
    }

    return <Navigate to={routeNames.ads.campaigns.home} />;
};

export default RoleGuard;
