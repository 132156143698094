import {
    DELETE_MUSIC,
    DELETE_MUSIC_FAIL,
    DELETE_MUSIC_SUCCESS,
    GET_GENRES,
    GET_GENRES_FAIL,
    GET_GENRES_SUCCESS,
    GET_MUSIC,
    GET_MUSIC_FAIL,
    GET_MUSIC_SUCCESS,
} from '../constants/music.constants';

const initialState = {
    deletedMusic: {
        loading: false,
        error: null,
        result: null,
    },
    music: {
        loading: true,
        error: null,
        result: {
            music: [],
            totalCount: 0,
        },
    },
    genres: {
        loading: true,
        error: null,
        genresData: [],
    },
};

const musicReducer = (state = initialState, action) => {
    const { type, payload, error, meta } = action;
    switch (type) {
        case DELETE_MUSIC:
            return { ...state, deletedMusic: { loading: true, error: null, result: null }, ...payload };
        case DELETE_MUSIC_SUCCESS:
            return {
                ...state,
                deletedMusic: { loading: false, error: null, result: payload.data },
                music: {
                    loading: false,
                    error: null,
                    result: {
                        music: state.music.result.music.filter((music) => music.id !== meta.previousAction.data.id),
                        totalCount: state.music.result.totalCount - 1,
                    },
                },
            };
        case DELETE_MUSIC_FAIL:
            return { ...state, deletedMusic: { loading: false, error, result: null } };

        default:
            return state;

        case GET_MUSIC:
            return {
                ...state,
                music: { loading: true, error: null, result: initialState.music.result },
            };
        case GET_MUSIC_SUCCESS:
            return {
                ...state,
                music: {
                    loading: false,
                    error: null,
                    result: {
                        music: [...state.music.result.music, ...payload.data.music],
                        totalCount: payload.data.totalCount,
                    },
                },
            };
        case GET_MUSIC_FAIL:
            return {
                ...state,
                music: { loading: false, error, result: null },
            };

        case GET_GENRES:
            return {
                ...state,
                genres: { loading: true, error: null, genresData: initialState.genres.genresData },
            };
        case GET_GENRES_SUCCESS:
            return {
                ...state,
                genres: {
                    loading: false,
                    error: null,
                    genresData: [...state.genres.genresData, ...payload.data.genres],
                },
            };
        case GET_GENRES_FAIL:
            return {
                ...state,
                genres: { loading: false, error, genresData: null },
            };
    }
};

export default musicReducer;
