import {
    DELETE_FEEDBACK,
    DELETE_FEEDBACK_FAIL,
    DELETE_FEEDBACK_SUCCESS,
    GET_FEEDBACKS,
    GET_FEEDBACKS_FAIL,
    GET_FEEDBACKS_SUCCESS,
    POST_RESOLVED,
    POST_RESOLVED_FAIL,
    POST_RESOLVED_SUCCESS,
} from '../constants/feedbacks.constants';

const initialState = {
    deletedFeedback: {
        loading: false,
        error: null,
        result: null,
    },
    feedbacks: {
        loading: true,
        error: null,
        result: {
            feedbacks: [],
            totalCount: 0,
        },
    },
    resolvedFeedback: {
        loading: false,
        error: null,
        result: null,
    },
};

const feedbackReducer = (state = initialState, action) => {
    const { type, payload, error, meta } = action;
    switch (type) {
        case DELETE_FEEDBACK:
            return { ...state, deletedFeedback: { loading: true, error: null, result: null }, ...payload };
        case DELETE_FEEDBACK_SUCCESS:
            return {
                ...state,
                deletedFeedback: { loading: false, error: null, result: payload.data },
                feedbacks: {
                    loading: false,
                    error: null,
                    result: {
                        feedbacks: state.feedbacks.result.feedbacks.filter(
                            (feedback) => feedback.id !== meta.previousAction.data.id
                        ),
                        totalCount: state.feedbacks.result.totalCount - 1,
                    },
                },
            };
        case DELETE_FEEDBACK_FAIL:
            return { ...state, deletedFeedback: { loading: false, error, result: null } };

        default:
            return state;

        case GET_FEEDBACKS:
            return {
                ...state,
                feedbacks: { loading: true, error: null, result: initialState.feedbacks.result },
            };
        case GET_FEEDBACKS_SUCCESS:
            return {
                ...state,
                feedbacks: {
                    loading: false,
                    error: null,
                    result: {
                        feedbacks: [...state.feedbacks.result.feedbacks, ...payload.data.feedbacks],
                        totalCount: payload.data.totalCount,
                    },
                },
            };
        case GET_FEEDBACKS_FAIL:
            return {
                ...state,
                feedbacks: {
                    loading: false,
                    error,
                    result: {
                        feedbacks: [],
                        totalCount: 0,
                    },
                },
            };
        case POST_RESOLVED:
            return { ...state, resolvedFeedback: { loading: true, error: null, result: null }, ...payload };

        case POST_RESOLVED_SUCCESS:
            return {
                ...state,
                resolvedFeedback: { loading: false, error: null, result: payload.data },
                result: {
                    feedbacks: state.feedbacks.result.feedbacks.map((feedback) => {
                        if (feedback.id === meta.previousAction.payload.request.data.id) {
                            feedback.resolved = meta.previousAction.payload.request.data.resolved;
                        }
                        return feedback;
                    }),
                },
            };
        case POST_RESOLVED_FAIL:
            return { ...state, resolvedFeedback: { loading: false, error, result: null } };
    }
};

export default feedbackReducer;
