import { lazy } from 'react';
import RoleGuard from 'src/components/RoleGuard';
import { Role } from 'src/models/roles';
import routeNames from 'src/utils/routeNames';

import { Loader } from './Loader';

// Ads
const Campaigns = Loader(lazy(() => import('src/pages/Ads')));
const CampaignRequests = Loader(lazy(() => import('src/pages/Ads/Requests')));

const managementRoutes = [
    {
        path: '',
        children: [
            {
                path: routeNames.ads.campaigns.home,
                element: <Campaigns />,
            },
            {
                path: routeNames.ads.campaigns.requests,
                element: (
                    <RoleGuard availableRoles={[Role.MODERATOR, Role.ADMIN]}>
                        <CampaignRequests />
                    </RoleGuard>
                ),
            },
        ],
    },
];

export default managementRoutes;
