import {
    DELETE_USER,
    DELETE_USER_FAIL,
    DELETE_USER_SUCCESS,
    GET_ROLES,
    GET_ROLES_FAIL,
    GET_ROLES_SUCCESS,
    GET_USERS,
    GET_USERS_FAIL,
    GET_USERS_SUCCESS,
    POST_ACTIVE,
    POST_ACTIVE_FAIL,
    POST_ACTIVE_SUCCESS,
} from '../constants/users.constants';

const initialState = {
    deletedUser: {
        loading: false,
        error: null,
        result: null,
    },
    activeUser: {
        loading: false,
        error: null,
        result: null,
    },
    users: {
        loading: true,
        error: null,
        result: {
            users: [],
            totalCount: 0,
        },
    },
    roles: {
        loading: true,
        error: null,
        rolesData: [],
    },
};

const userReducer = (state = initialState, action) => {
    const { type, payload, error, meta } = action;
    switch (type) {
        case DELETE_USER:
            return { ...state, deletedUser: { loading: true, error: null, result: null }, ...payload };
        case DELETE_USER_SUCCESS:
            return {
                ...state,
                deletedUser: { loading: false, error: null, result: payload.data },
                users: {
                    loading: false,
                    error: null,
                    result: {
                        users: state.users.result.users.filter((user) => user.id !== meta.previousAction.data.id),
                        totalCount: state.users.result.totalCount - 1,
                    },
                },
            };
        case DELETE_USER_FAIL:
            return { ...state, deletedUser: { loading: false, error, result: null } };

        default:
            return state;

        case GET_USERS:
            return {
                ...state,
                users: { loading: true, error: null, result: initialState.users.result },
            };
        case GET_USERS_SUCCESS:
            return {
                ...state,
                users: {
                    loading: false,
                    error: null,
                    result: {
                        users: [...state.users.result.users, ...payload.data.users],
                        totalCount: payload.data.totalCount,
                    },
                },
            };
        case GET_USERS_FAIL:
            return {
                ...state,
                users: { loading: false, error, result: null },
            };

        case GET_ROLES:
            return {
                ...state,
                roles: { loading: true, error: null, rolesData: initialState.roles.rolesData },
            };
        case GET_ROLES_SUCCESS:
            return {
                ...state,
                roles: {
                    loading: false,
                    error: null,
                    rolesData: [...state.roles.rolesData, ...payload.data.roles],
                },
            };
        case GET_ROLES_FAIL:
            return {
                ...state,
                roles: { loading: false, error, rolesData: null },
            };
        case POST_ACTIVE:
            return { ...state, activeUser: { loading: true, error: null, result: null }, ...payload };

        case POST_ACTIVE_SUCCESS:
            return {
                ...state,
                activeUser: { loading: false, error: null, result: payload.data },
                result: {
                    users: state.users.result.users.map((user) => {
                        if (user.id === meta.previousAction.payload.request.data.id) {
                            user.active = meta.previousAction.payload.request.data.active;
                        }
                        return user;
                    }),
                },
            };
        case POST_ACTIVE_FAIL:
            return { ...state, activeUser: { loading: false, error, result: null } };
    }
};

export default userReducer;
