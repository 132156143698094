const CREATE_PAYMENT_METHOD = 'CREATE_PAYMENT_METHOD';
const CREATE_PAYMENT_METHOD_SUCCESS = 'CREATE_PAYMENT_METHOD_SUCCESS';
const CREATE_PAYMENT_METHOD_FAIL = 'CREATE_PAYMENT_METHOD_FAIL';

const DELETE_PAYMENT_METHOD = 'DELETE_PAYMENT_METHOD';
const DELETE_PAYMENT_METHOD_SUCCESS = 'DELETE_PAYMENT_METHOD_SUCCESS';
const DELETE_PAYMENT_METHOD_FAIL = 'DELETE_PAYMENT_METHOD_FAIL';

const GET_PAYMENT_METHODS = 'GET_PAYMENT_METHODS';
const GET_PAYMENT_METHODS_SUCCESS = 'GET_PAYMENT_METHODS_SUCCESS';
const GET_PAYMENT_METHODS_FAIL = 'GET_PAYMENT_METHODS_FAIL';

const GET_PROFILE_PAYMENT_METHODS = 'GET_PROFILE_PAYMENT_METHODS';
const GET_PROFILE_PAYMENT_METHODS_SUCCESS = 'GET_PROFILE_PAYMENT_METHODS_SUCCESS';
const GET_PROFILE_PAYMENT_METHODS_FAIL = 'GET_PROFILE_PAYMENT_METHODS_FAIL';

const SET_PREFERRED_PAYMENT_METHOD = 'SET_PREFERRED_PAYMENT_METHOD';
const SET_PREFERRED_PAYMENT_METHOD_SUCCESS = 'SET_PREFERRED_PAYMENT_METHOD_SUCCESS';
const SET_PREFERRED_PAYMENT_METHOD_FAIL = 'SET_PREFERRED_PAYMENT_METHOD_FAIL';

export {
    CREATE_PAYMENT_METHOD,
    CREATE_PAYMENT_METHOD_FAIL,
    CREATE_PAYMENT_METHOD_SUCCESS,
    DELETE_PAYMENT_METHOD,
    DELETE_PAYMENT_METHOD_FAIL,
    DELETE_PAYMENT_METHOD_SUCCESS,
    GET_PAYMENT_METHODS,
    GET_PAYMENT_METHODS_FAIL,
    GET_PAYMENT_METHODS_SUCCESS,
    GET_PROFILE_PAYMENT_METHODS,
    GET_PROFILE_PAYMENT_METHODS_FAIL,
    GET_PROFILE_PAYMENT_METHODS_SUCCESS,
    SET_PREFERRED_PAYMENT_METHOD,
    SET_PREFERRED_PAYMENT_METHOD_FAIL,
    SET_PREFERRED_PAYMENT_METHOD_SUCCESS,
};
