import { lazy } from 'react';
import routeNames from 'src/utils/routeNames';

import { Loader } from './Loader';

const Payment = Loader(lazy(() => import('src/pages/Payments')));
const PaymentHistory = Loader(lazy(() => import('src/pages/PaymentsHistory')));

const managementRoutes = [
    {
        path: '',
        children: [
            {
                path: routeNames.management.coins,
                element: <Payment />,
            },
            {
                path: routeNames.management.history,
                element: <PaymentHistory />,
            },
        ],
    },
];

export default managementRoutes;
