import {
    CREATE_PAYMENT_METHOD,
    CREATE_PAYMENT_METHOD_FAIL,
    CREATE_PAYMENT_METHOD_SUCCESS,
    DELETE_PAYMENT_METHOD,
    DELETE_PAYMENT_METHOD_FAIL,
    DELETE_PAYMENT_METHOD_SUCCESS,
    GET_PAYMENT_METHODS,
    GET_PAYMENT_METHODS_FAIL,
    GET_PAYMENT_METHODS_SUCCESS,
    GET_PROFILE_PAYMENT_METHODS,
    GET_PROFILE_PAYMENT_METHODS_FAIL,
    GET_PROFILE_PAYMENT_METHODS_SUCCESS,
    SET_PREFERRED_PAYMENT_METHOD,
    SET_PREFERRED_PAYMENT_METHOD_FAIL,
    SET_PREFERRED_PAYMENT_METHOD_SUCCESS,
} from '../constants/payments.constants';

const initialState = {
    createdPaymentMethod: {
        loading: false,
        error: null,
        result: null,
    },
    deletedPaymentMethod: {
        loading: false,
        error: null,
        result: null,
    },
    paymentMethods: {
        loading: true,
        error: null,
        result: [],
    },
    preferredPaymentMethod: {
        loading: false,
        error: null,
        result: null,
    },
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const paymentReducer = (state = initialState, action) => {
    const { type, payload, error } = action;
    switch (type) {
        case CREATE_PAYMENT_METHOD:
            return { ...state, createdPaymentMethod: { loading: true, error: null, result: null }, ...payload };
        case CREATE_PAYMENT_METHOD_SUCCESS:
            return {
                ...state,
                createdPaymentMethod: {
                    loading: false,
                    error: null,
                    result: payload.data,
                },
                paymentMethods: {
                    loading: false,
                    error: null,
                    result: [
                        payload.data.data,
                        ...state.paymentMethods.result.map((method) => {
                            return { ...method, isPreferred: false };
                        }),
                    ],
                },
            };
        case CREATE_PAYMENT_METHOD_FAIL:
            return { ...state, createdPaymentMethod: { loading: false, error, result: null } };

        case DELETE_PAYMENT_METHOD:
            return { ...state, deletedPaymentMethod: { loading: true, error: null, result: null }, ...payload };
        case DELETE_PAYMENT_METHOD_SUCCESS:
            return {
                ...state,
                deletedPaymentMethod: { loading: false, error: null, result: payload.data },
                paymentMethods: {
                    loading: false,
                    error: null,
                    result: state.paymentMethods.result
                        .filter((method) => method.id !== action.meta.previousAction.requestParams.id)
                        .map((method) => {
                            return { ...method, isPreferred: payload.data.preferredId === method.id };
                        }),
                },
            };
        case DELETE_PAYMENT_METHOD_FAIL:
            return { ...state, deletedPaymentMethod: { loading: false, error, result: null } };

        case SET_PREFERRED_PAYMENT_METHOD:
            return { ...state, preferredPaymentMethod: { loading: true, error: null, result: null }, ...payload };
        case SET_PREFERRED_PAYMENT_METHOD_SUCCESS:
            return {
                ...state,
                paymentMethods: {
                    loading: false,
                    error: null,
                    result: state.paymentMethods.result.map((method) => {
                        return { ...method, isPreferred: method.id === payload.data.id };
                    }),
                },
                preferredPaymentMethod: { loading: false, error: null, result: payload.data },
            };
        case SET_PREFERRED_PAYMENT_METHOD_FAIL:
            return {
                ...state,
                preferredPaymentMethod: { loading: false, error: payload.error, result: null },
            };

        case GET_PAYMENT_METHODS:
            return {
                ...state,
                paymentMethods: { loading: true, error: null, result: initialState.paymentMethods.result },
            };
        case GET_PAYMENT_METHODS_SUCCESS:
            return { ...state, paymentMethods: { loading: false, error: null, result: payload.data.paymentMethods } };
        case GET_PAYMENT_METHODS_FAIL:
            return {
                ...state,
                paymentMethods: { loading: false, error, result: initialState.paymentMethods.result },
            };

        case GET_PROFILE_PAYMENT_METHODS:
            return {
                ...state,
                paymentMethods: { loading: true, error: null, result: initialState.paymentMethods.result },
            };
        case GET_PROFILE_PAYMENT_METHODS_SUCCESS:
            return { ...state, paymentMethods: { loading: false, error: null, result: payload.data.paymentMethods } };
        case GET_PROFILE_PAYMENT_METHODS_FAIL:
            return {
                ...state,
                paymentMethods: { loading: false, error, result: initialState.paymentMethods.result },
            };

        default:
            return state;
    }
};

export default paymentReducer;
