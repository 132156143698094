import { useAuth0 } from '@auth0/auth0-react';
import AccountBoxTwoToneIcon from '@mui/icons-material/AccountBoxTwoTone';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import { Avatar, Box, Button, Divider, List, ListItem, ListItemText, Popover, styled, Typography } from '@mui/material';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { logout as LogoutAction } from 'src/store/actions/authenticationActions';
import config from 'src/utils/config';
import routeNames from 'src/utils/routeNames';
import Cookies from 'universal-cookie';

const UserBoxButton = styled(Button)(
    ({ theme }) => `
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(1)};
`
);

const MenuUserBox = styled(Box)(
    ({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        padding: ${theme.spacing(2)};
`
);

const UserBoxText = styled(Box)(
    ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = styled(Typography)(
    ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
`
);

const UserBoxDescription = styled(Typography)(
    ({ theme }) => `
        color: ${theme.palette.secondary.light}
`
);

const HeaderUserbox = () => {
    const { t }: { t: any } = useTranslation();

    const ref = useRef<any>(null);
    const [isOpen, setOpen] = useState<boolean>(false);
    const cookies = new Cookies();
    const handleOpen = (): void => {
        setOpen(true);
    };
    const handleClose = (): void => {
        setOpen(false);
    };
    const { logout, user } = useAuth0();
    const dispatch = useDispatch();

    const handleLogout = async (event) => {
        event.preventDefault();
        try {
            cookies.remove('ozlynk:credentials', { path: '/', domain: config.cookieDomain });
            dispatch(LogoutAction());
            logout();
        } catch (error) {
            //setErrorMessage(error.data.message.toString());
        }
    };
    return (
        <>
            <UserBoxButton color="secondary" ref={ref} onClick={handleOpen}>
                <Avatar variant="rounded" alt={user.meta?.username} src={user.meta?.photo} />
                <Box
                    component="span"
                    sx={{
                        display: { xs: 'none', md: 'inline-block' },
                    }}
                >
                    <UserBoxText>
                        <UserBoxLabel variant="body1">{user.meta?.username}</UserBoxLabel>
                        <UserBoxDescription variant="body2">{user.jobtitle}</UserBoxDescription>
                    </UserBoxText>
                </Box>
                <Box
                    component="span"
                    sx={{
                        display: { xs: 'none', sm: 'inline-block' },
                    }}
                >
                    <ExpandMoreTwoToneIcon
                        sx={{
                            ml: 1,
                        }}
                    />
                </Box>
            </UserBoxButton>
            <Popover
                disableScrollLock
                anchorEl={ref.current}
                onClose={handleClose}
                open={isOpen}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <MenuUserBox
                    sx={{
                        minWidth: 210,
                    }}
                    display="flex"
                >
                    <Avatar variant="rounded" alt={user.meta?.username} src={user.meta?.photo} />
                    <UserBoxText>
                        <UserBoxLabel variant="body1">{user.meta?.username}</UserBoxLabel>
                        <UserBoxDescription variant="body2">{user.jobtitle}</UserBoxDescription>
                    </UserBoxText>
                </MenuUserBox>
                <Divider
                    sx={{
                        mb: 0,
                    }}
                />
                <List
                    sx={{
                        p: 1,
                    }}
                    component="nav"
                >
                    <ListItem
                        onClick={() => {
                            handleClose();
                        }}
                        button
                        to={routeNames.users.profile}
                        component={NavLink}
                    >
                        <AccountBoxTwoToneIcon fontSize="small" />
                        <ListItemText primary={t('Profile')} />
                    </ListItem>
                </List>
                <Divider />
                <Box m={1}>
                    <Button color="primary" fullWidth onClick={handleLogout}>
                        <LockOpenTwoToneIcon
                            sx={{
                                mr: 1,
                            }}
                        />
                        {t('Sign out')}
                    </Button>
                </Box>
            </Popover>
        </>
    );
};

export default HeaderUserbox;
