import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { CssBaseline } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import { useRoutes } from 'react-router-dom';
import router from 'src/router';

import ThemeProvider from './theme/ThemeProvider';

const App = () => {
    const content = useRoutes(router);
    return (
        <ThemeProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <SnackbarProvider
                    maxSnack={6}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                >
                    <CssBaseline />
                    {content}
                </SnackbarProvider>
            </LocalizationProvider>
        </ThemeProvider>
    );
};
export default App;
