import {
    ADD_USERNAME,
    ADD_USERNAME_FAIL,
    ADD_USERNAME_SUCCESS,
    DELETE_USERNAME,
    DELETE_USERNAME_FAIL,
    DELETE_USERNAME_SUCCESS,
    EDIT_USERNAME,
    EDIT_USERNAME_FAIL,
    EDIT_USERNAME_SUCCESS,
    GET_USERNAMES,
    GET_USERNAMES_FAIL,
    GET_USERNAMES_SUCCESS,
} from '../constants/usernames.constants';

const initialState = {
    usernames: {
        loading: false,
        error: null,
        result: {
            usernames: [],
            totalCount: 0,
        },
    },
};

const usernamesCheckReducer = (state = initialState, action) => {
    const { type, error, payload, meta } = action;
    switch (type) {
        case GET_USERNAMES:
            return {
                ...state,
                usernames: {
                    loading: true,
                    error: null,
                    result: initialState.usernames.result ?? [],
                },
            };
        case GET_USERNAMES_SUCCESS:
            return {
                ...state,
                usernames: {
                    loading: false,
                    error: null,
                    result: {
                        usernames: [...state.usernames.result.usernames, ...payload.data.usernames],
                        totalCount: payload.data.totalCount,
                    },
                },
            };
        case GET_USERNAMES_FAIL:
            return {
                ...state,
                usernames: {
                    loading: false,
                    error,
                    result: {
                        usernames: [],
                        totalCount: 0,
                    },
                },
            };
        case DELETE_USERNAME:
            return { ...state, usernames: { ...state.usernames, loading: true, error: null } };
        case DELETE_USERNAME_SUCCESS:
            return {
                ...state,
                usernames: {
                    loading: false,
                    error: null,
                    result: {
                        usernames: state.usernames.result.usernames.filter(
                            (usernames) => usernames.id !== meta.previousAction.data.id
                        ),
                        totalCount: state.usernames.result.totalCount - 1,
                    },
                },
            };
        case DELETE_USERNAME_FAIL:
            return { ...state, usernames: { ...state.usernames, loading: false, error } };

        case EDIT_USERNAME:
            return { ...state, usernames: { loading: true, error: null, result: state.usernames.result } };

        case EDIT_USERNAME_SUCCESS:
            return {
                ...state,
                usernames: {
                    loading: false,
                    error: null,
                    result: {
                        totalCount: state.usernames.result.totalCount,
                        usernames: state.usernames.result.usernames.map((username) => {
                            if (username.id === meta.previousAction.payload.request.data.id) {
                                username.word = meta.previousAction.payload.request.data.word;
                            }
                            return username;
                        }),
                    },
                },
            };
        case EDIT_USERNAME_FAIL:
            return { ...state, usernames: { loading: false, error, result: null } };

        case ADD_USERNAME:
            return { ...state, usernames: { loading: true, error: null, result: state.usernames.result } };

        case ADD_USERNAME_SUCCESS:
            return {
                ...state,
                usernames: {
                    loading: false,
                    error: null,
                    result: {
                        totalCount: state.usernames.result.totalCount + 1,
                        usernames: [payload.data.username, ...state.usernames.result.usernames],
                    },
                },
            };
        case ADD_USERNAME_FAIL:
            return { ...state, usernames: { loading: false, error, result: null } };

        default:
            return state;
    }
};

export default usernamesCheckReducer;
