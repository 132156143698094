import { RouteObject } from 'react-router';
import { Navigate } from 'react-router-dom';
import Authenticated from 'src/components/Authenticated';
import BaseLayout from 'src/layouts/BaseLayout';
import CollapsedSidebarLayout from 'src/layouts/CollapsedSidebarLayout';

import adsRoutes from './ads';
import authRoutes from './auth';
import baseRoutes from './base';
import paymentsRoutes from './coins';
import emailsRoutes from './emails';
import feedbackRoutes from './feedback';
import gamesRoutes from './games';
import generatedUsers from './generatedUsers';
import musicRoutes from './music';
import postsRoutes from './posts';
import reportsRoutes from './reports';
import settingsRoutes from './settings';
import soundEffectRoutes from './soundEffect';
import stickersRoutes from './stickers';
import usernamesRoutes from './usernames';
import usersRoutes from './users';

const router: RouteObject[] = [
    // Auth Routes
    {
        path: 'auth',
        children: authRoutes,
    },

    // Main Routes
    {
        path: '',
        element: <BaseLayout />,
        children: baseRoutes,
    },

    // Authenticated Routes
    {
        path: '',
        element: (
            <Authenticated>
                <CollapsedSidebarLayout />
            </Authenticated>
        ),
        children: [
            {
                path: '',
                element: <Navigate to="dashboards" replace />,
            },
            {
                path: '',
                children: usersRoutes,
            },
            {
                path: '',
                children: generatedUsers,
            },
            {
                path: '',
                children: adsRoutes,
            },
            {
                path: '',
                children: stickersRoutes,
            },
            {
                path: '',
                children: gamesRoutes,
            },
            {
                path: '',
                children: soundEffectRoutes,
            },
            {
                path: '',
                children: musicRoutes,
            },
            {
                path: '',
                children: feedbackRoutes,
            },
            {
                path: '',
                children: postsRoutes,
            },
            {
                path: '',
                children: settingsRoutes,
            },
            {
                path: '',
                children: usernamesRoutes,
            },
            {
                path: '',
                children: emailsRoutes,
            },
            {
                path: '',
                children: settingsRoutes,
            },
            {
                path: '',
                children: usernamesRoutes,
            },
            {
                path: '',
                children: emailsRoutes,
            },
            { path: '', children: reportsRoutes },
            { path: '', children: paymentsRoutes },
        ],
    },
];

export default router;
